
import style from './App.module.scss';
import { usePleaseStay } from 'react-use-please-stay';
import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import 'animate.css';
import { InView, useInView } from 'react-intersection-observer';


function App() {
   const [isMobile, setIsMobile] = React.useState(false);


  React.useEffect(() => {
    const checkIfMobile = () => {
      const mobileWidth = 768; // You can adjust this value based on your design considerations

      setIsMobile(window.innerWidth < mobileWidth);
    };

    // Initial check on mount
    checkIfMobile();

    // Listen for window resize events
    window.addEventListener('resize', checkIfMobile);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', checkIfMobile);
    };
  }, []);

  const [send, setsend] = React.useState(0);
  usePleaseStay({
    titles: [
      "Nikoloz Tchkoidze",
      "Frontend",
      "Developer :)"
    ],
    interval: 1500,
    animationType: Animation.LOOP,
    alwaysRunAnimations: true
})
// const [active, setactive] = React.useState(false);
// const {ref, inView} = useInView({
// threshold: 0.5,
// triggerOnce: true,
// });

// console.log(active);
// React.useEffect(() => {
// if(inView){
//    setactive(true)
// }
// }, [inView]);




const form = useRef();

const sendEmail = (e) => {
  e.preventDefault();

  emailjs.sendForm('service_mgr18do', 'template_lszmcug', form.current, 'K6Ifbus5v6zqJTVba')
    .then((result) => {
        setsend(1);
        console.log(result.text);
    }, (error) => {
        setsend(2);
        console.log(error.text);
    });
   };

  return (
<div className={style.App}>
   <div className={style.main}>
      <div className={`${style.header} ${"animate__animated animate__bounceInDown"}`}>
         <div className={style.header_name}>NIKA</div>
      </div>
      <div className={style.firstpage}>
         <div className={style.firstpage_cont}>
            <div className={`${style.firstpage_left} ${"animate__animated animate__bounceInLeft"}`}>
               <div className={style.firstpage_left_intr}>HI THERE, I`M</div>
               <div className={style.firstpage_left_name}>NIKOLOZ TCHKOIDZE</div>
               <div className={style.firstpage_left_profession}>FRONTEND DEVELOPER</div>
               <div className={style.firstpage_left_desc}>Enthusiastic Front-End Developer to contribute to team success through hard work, attention to detail and excellent organizational skills. Clear understanding of Task and and training in Front-End. Motivated to learn, grow and excel in Programming Industry.</div>
               <a  href="https://www.linkedin.com/in/nikoloz-tchkoidze-b2541622a/" target="_blank"  className={style.firstpage_left_button}   >HIRE ME !</a>
            </div>
            <div className='animate__animated animate__bounceInRight'>
            <div className={style.firstpage_right}></div>
            </div>
         </div>
      </div>
      <div className={style.secondpage}>
         <div className={style.secondpage_container}>
            <div className={`${style.secondpage_leftside} ${"animate__animated animate__bounceInLeft "}`}>
               <div className={style.secondpage_leftside_header}>ABOUT ME</div>
               <div className={style.secondpage_leftside_box}>
                  <div className={style.secondpage_leftside_box_avatar}></div>
               </div>
            </div>
            <div className={`${style.secondpage_rightside} ${"animate__animated animate__bounceInRight"}`}>
               <div className={style.secondpage_rightside_name}>Nikoloz Tchkoidze</div>
               <div className={style.secondpage_rightside_desc}>I am Nikoloz Tchkoidze, from the country of Georgia. As a seasoned frontend developer, I bring three years of hands-on experience in the dynamic world of web development. Throughout my journey, I have successfully contributed my skills to the completion of over 40 projects, showcasing my proficiency and dedication to delivering high-quality solutions. I am excited about the prospect of further leveraging my expertise to create innovative and visually compelling experiences in the ever-evolving field of frontend development.</div>
               <div className={style.secondpage_rightside_experience}>
                  <div className={style.secondpage_rightside_experience_year}><span className={style.fixer}>3</span><span className={style.fixer2}>Years of experience</span></div>
                  <div className={style.secondpage_rightside_experience_proj}><span className={style.fixer}>40+</span><span className={style.fixer2}>Projects done</span></div>
               </div>
               <div className={style.secondpage_rightside_allskills}>
                  <div>
                     <div className={style.secondpage_rightside_skillname}><span style={{color: "#2293fd"}}>MY</span> SKILLS</div>
                     <div className={style.secondpage_rightside_skills}>
                        <div className={style.secondpage_rightside_skill_cont}>
                           <div className={style.secondpage_rightside_skilltxt}>HTML</div>
                           <progress className={style.secondpage_rightside_skill} value="90" max="100"></progress>
                        </div>
                        <div className={style.secondpage_rightside_skill_cont}>
                           <div className={style.secondpage_rightside_skilltxt}>CSS</div>
                           <progress className={style.secondpage_rightside_skill} value="85" max="100"></progress>
                        </div>
                        <div className={style.secondpage_rightside_skill_cont}>
                           <div className={style.secondpage_rightside_skilltxt}>JAVASCRIPT</div>
                           <progress className={style.secondpage_rightside_skill} value="80" max="100"></progress>
                        </div>
                        <div className={style.secondpage_rightside_skill_cont}>
                           <div className={style.secondpage_rightside_skilltxt}>REACT</div>
                           <progress className={style.secondpage_rightside_skill} value="70" max="100"></progress>
                        </div>
                        <div className={style.secondpage_rightside_skill_cont}>
                           <div className={style.secondpage_rightside_skilltxt}>VUE</div>
                           <progress className={style.secondpage_rightside_skill} value="55" max="100"></progress>
                        </div>
                        <div className={style.secondpage_rightside_skill_cont}>
                           <div className={style.secondpage_rightside_skilltxt}>PHOTOSHOP</div>
                           <progress className={style.secondpage_rightside_skill} value="75" max="100"></progress>
                        </div>
                        <div className={style.secondpage_rightside_skill_cont}>
                           <div className={style.secondpage_rightside_skilltxt}>FIGMA</div>
                           <progress className={style.secondpage_rightside_skill} value="90" max="100"></progress>
                        </div>
                     </div>
                  </div>
                  <div>
                     <div className={style.secondpage_rightside_skillname}><span style={{color: "#2293fd"}}>MY</span> LANGUAGES</div>
                     <div className={style.secondpage_rightside_skills}>
                        <div className={style.secondpage_rightside_skill_cont}>
                           <div className={style.secondpage_rightside_skilltxt}>GEORGIAN</div>
                           <progress className={style.secondpage_rightside_skill} value="100" max="100"></progress>
                        </div>
                        <div className={style.secondpage_rightside_skill_cont}>
                           <div className={style.secondpage_rightside_skilltxt}>RUSSIAN</div>
                           <progress className={style.secondpage_rightside_skill} value="100" max="100"></progress>
                        </div>
                        <div className={style.secondpage_rightside_skill_cont}>
                           <div className={style.secondpage_rightside_skilltxt}>ENGLISH</div>
                           <progress className={style.secondpage_rightside_skill} value="85" max="100"></progress>
                        </div>
                        <div className={style.secondpage_rightside_skill_cont}>
                           <div className={style.secondpage_rightside_skilltxt}>CZECH</div>
                           <progress className={style.secondpage_rightside_skill} value="20" max="100"></progress>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <div className={`${style.thirdpage} ${"animate__animated animate__bounceInLeft"}`}>
         <div className={style.thirdpage_container}>
            <div className={style.thirdpage_header}>OFFERED SERVICES</div>
            <div className={style.thirdpage_boxes}>
               <div className={style.thirdpage_box}>
                  <div className={style.thirdpage_box_img1}></div>
                  <div className={style.thirdpage_box_name}>WEB DEVELOPMENT</div>
                  <div className={style.thirdpage_box_desc}>
Web development, alternatively referred to as website development, encompasses the processes involved in designing, constructing, and sustaining websites and web applications accessible through a browser. Additionally, it may involve aspects such as web design, programming, and the management of databases.</div>
               </div>
               <div className={style.thirdpage_box}>
                  <div className={style.thirdpage_box_img2}></div>
                  <div className={style.thirdpage_box_name}>MENTOR</div>
                  <div className={style.thirdpage_box_desc}>Are you ready to embark on a journey into the exciting world of front-end development? Look no further! With over 3 years of hands-on industry experience and a genuine passion for teaching, I'm here to be your expert guide.</div>
                  </div>
               <div className={style.thirdpage_box}>
                  <div className={style.thirdpage_box_img3}></div>
                  <div className={style.thirdpage_box_name}>FRONTEND DEVELOPMENT</div>
                  <div className={style.thirdpage_box_desc}>
A front-end developer is a software professional specialized in crafting and designing the user interface (UI) and user experience (UX) for websites and web applications. The core duty of a front-end developer is to guarantee that the visual and interactive elements of a website or application are not only user-friendly but also aesthetically appealing and functionally efficient.</div>
               </div>
            </div>
         </div>
      </div>
      <div className={`${style.fourthpage} ${"animate__animated animate__bounceInRight"}`}>
         <div className={style.fourthpage_container}>
            <div className={style.fourthpage_header}>MY PROJECTS</div>
            <div className={style.fourthpage_boxes}>
               <a href="https://preview.hype.ge/" target="_blank" className={`${style.fourthpage_box} ${isMobile == true ? style.mobshad : null}`}>
                  {isMobile == true ? <div className={style.mobtext}>Only For Desktop!</div> : null}
                  <div className={style.shadow}> 
                     <div className={style.shadow_cont}> 
                        <div className={style.fourthpage_projectname}>Saechvo BOX</div>
                        <div className={style.fourthpage_projectdesc}>Developed a responsive (Not mobile) website tailored for e-commerce functionality using React design was presented to me via Adobe Photoshop [Customer - Saechvo kaci]
                        </div>
                     </div>
                  </div>
                  <div className={`${style.fourthpage_box_img} ${style.p1}`}></div>
               </a>
               <a href="https://history.hype.ge/" target="_blank" className={style.fourthpage_box}>
                  <div className={style.shadow}>
                     <div className={style.shadow_cont}>
                        <div className={style.fourthpage_projectname}>History Handmade</div>
                        <div className={style.fourthpage_projectdesc}>Together with my colleague, I took part in the development of a comprehensive, fully responsive website tailored specifically for an e-commerce [Customer - Brand History]
                        </div>
                     </div>
                  </div>
                  <div className={`${style.fourthpage_box_img} ${style.p2}`}></div>
               </a>
               <a href="http://test.nika.hype.ge/" target="_blank" className={style.fourthpage_box}>
                  <div className={style.shadow}>
                     <div className={style.shadow_cont}>
                        <div className={style.fourthpage_projectname}>Functional Phone</div>
                        <div className={style.fourthpage_projectdesc}>Developed a fully functional mobile phone, complete with a banking application and more, for the GTAV game project. [Customer - Real-Life Project]
                        </div>
                     </div>
                  </div>
                  <div className={`${style.fourthpage_box_img} ${style.p3}`}></div>
               </a>
               <a href="http://preview.nika.hype.ge/" target="_blank" className={style.fourthpage_box}>
                  <div className={style.shadow}>
                     <div className={style.shadow_cont}>
                        <div className={style.fourthpage_projectname}>Nemsiscraft</div>
                        <div className={style.fourthpage_projectdesc}>Developed a website tailored to the PC gaming audience, focusing on gaming content and experiences. [Customer - Nemsiscraft Project]
                        </div>
                     </div>
                  </div>
                  <div className={`${style.fourthpage_box_img} ${style.p4}`}></div>
               </a>
               {/* <a href="https://check.hype.ge/" target="_blank" className={style.fourthpage_box}>
                  <div className={style.shadow}>
                     <div className={style.shadow_cont}>
                        <div className={style.fourthpage_projectname}>Castte Role Play</div>
                        <div className={style.fourthpage_projectdesc}>Developed a website tailored to the PC gaming audience, focusing on gaming content and experiences. [Customer - Castte Role Play]
                        </div>
                     </div>
                  </div>
                  <div className={`${style.fourthpage_box_img} ${style.p5}`}></div>
               </a> */}
               <a href="https://imgur.com/a/YAKD3T0" target="_blank" className={style.fourthpage_box}>
                  <div className={style.shadow}>
                     <div className={style.shadow_cont}>
                        <div className={style.fourthpage_projectname}>UI Menus</div>
                        <div className={style.fourthpage_projectdesc}>Developed numerous responsive UI menus for a gaming project in GTAV, ensuring compatibility with various PC and laptop resolutions and incorporating extensive functionality. [Customer - Real Life Project]
                        </div>
                     </div>
                  </div>
                  <div className={`${style.fourthpage_box_img} ${style.p5}`}></div>
               </a>
               <a href="http://check.nika.hype.ge/" target="_blank" className={`${style.fourthpage_box} ${isMobile == true ? style.mobshad : null}`}>
               {isMobile == true ? <div className={style.mobtext}>Only For Desktop!</div> : null}
                  <div className={style.shadow}>
                     <div className={style.shadow_cont}>
                        <div className={style.fourthpage_projectname}>Inventory UI</div>
                        <div className={style.fourthpage_projectdesc}>Developed UI character inventory Menu for Gtav community server [Customer - Hype Project]
                        </div>
                     </div>
                  </div>
                  <div className={`${style.fourthpage_box_img} ${style.p6}`}></div>
               </a>
               <a href="http://check.hype.ge/" target="_blank" className={`${style.fourthpage_box} ${isMobile == true ? style.mobshad : null}`}>
               {isMobile == true ? <div className={style.mobtext}>Only For Desktop!</div> : null}
                  <div className={style.shadow}>
                     <div className={style.shadow_cont}>
                        <div className={style.fourthpage_projectname}>Main Menu Design</div>
                        <div className={style.fourthpage_projectdesc}>Developed UI Main Character Menu for Gtav community server [Customer - Hype Project]
                        </div>
                     </div>
                  </div>
                  <div className={`${style.fourthpage_box_img} ${style.p7}`}></div>
               </a>
               <a href="http://shop.hype.ge/" target="_blank" className={style.fourthpage_box}>
                  <div className={style.shadow}>
                     <div className={style.shadow_cont}>
                        <div className={style.fourthpage_projectname}>Accesories Shop</div>
                        <div className={style.fourthpage_projectdesc}>Together with my colleague, I took part in the development of a comprehensive, fully responsive website tailored specifically for an e-commerce [Customer - Hype Accesories Shop]
                        </div>
                     </div>
                  </div>
                  <div className={`${style.fourthpage_box_img} ${style.p8}`}></div>
               </a>
            </div>
         </div>
      </div>
      <div className={`${style.fifthpage} ${"animate__animated animate__bounceInUp"}`}>
         <div className={style.fifthpage_container}>
            <div className={style.fifthpage_header}>CONTACT <span className={style.bluer}>ME</span></div>
            <div className={style.fifthpage_dec_container}>
               <div className={style.fifthpage_dec_line}></div>
               <div className={style.fifthpage_dec_icon}></div>
               <div className={style.fifthpage_dec_line}></div>
            </div>
            <div className={style.fifthpage_inner_cont}>
               <div className={style.fifthpage_inner_links}>
                  <div className={style.fifthpage_links_box}>
                     <div className={style.fifthpage_links_box_left}>
                        <div className={style.fifthpage_links_box_header}>PHONE</div>
                        <div className={style.fifthpage_links_box_desc}>+995 577 21 80 12</div>
                     </div>
                     <div className={style.fifthpage_links_box_decline}></div>
                     <div className={style.fifthpage_links_box_right_phone}></div>
                  </div>
                  <div className={style.fifthpage_links_box}>
                     <div className={style.fifthpage_links_box_left}>
                        <div className={style.fifthpage_links_box_header}>LOCATION</div>
                        <div className={style.fifthpage_links_box_desc}>Tbilisi, Georgia</div>
                     </div>
                     <div className={style.fifthpage_links_box_decline}></div>
                     <div className={style.fifthpage_links_box_right_location}></div>
                  </div>
                  <div className={`${style.fifthpage_links_box} ${style.fifthpage_links_fx}`} >
                  <div className={style.fifthpage_links_box_left}>
                     <div className={style.fifthpage_links_box_header}>EMAIL</div>
                     <div className={style.fifthpage_links_box_desc}>nikoloz.tchkoidze@gmail.com</div>
                  </div>
                  <div className={style.fifthpage_links_box_decline}></div>
                  <div className={style.fifthpage_links_box_right_email}></div>
               </div>
            </div>
            <div className={style.fifthpage_inner_form}>
               <form ref={form} onSubmit={sendEmail}>
                  <div className={style.fifthpage.inner_form_head}>
                     <input className={style.fifthpage_small_inp} autoComplete="off" placeholder="Name" type='text' name="from_name"></input>
                     <input className={style.fifthpage_small_inp} autoComplete="off" placeholder="Email" type='email' name="from_email" required></input>
                     <input className={`${style.fifthpage_small_inp} ${style.fifthpage_fx}`} autoComplete="off" type='number' placeholder="Phone" name="from_phone"></input>
                  </div>
                  <input className={style.fifthpage_med_inp} autoComplete="off" placeholder="Subject" type='text' name="from_subject" ></input>
                  <textarea className={style.fifthpage_big_inp} autoComplete="off" rows="4" cols="150" placeholder="Message" name="message" required></textarea>
                  <button className={`${style.fifthpage_sendbtn} ${send == 1 ? (style.fifthpage_success) : send == 2 ? (style.fifthpage_error) : null}`} value="Send">{send == 1 ? "EMAIL WAS SENT SUCCESSFULLY" : send == 2 ? "ERROR" : "SEND"}</button>
               </form>
            </div>
            <div className={style.fifthpage_inner_social}>
               <a href="https://www.linkedin.com/in/nikoloz-tchkoidze-b2541622a/" target="_blank" className={style.fifthpage_inner_link}>
                  <div className={style.fifthpage_inner_linkedin_icon}></div>
                  <div className={style.fifthpage_inner_link_text}>LinkedIN</div>
               </a>
               <a href="https://www.instagram.com/hype_jigaro/" target="_blank" className={style.fifthpage_inner_link}>
                  <div className={style.fifthpage_inner_instagram_icon}></div>
                  <div className={style.fifthpage_inner_link_text}>Instagram</div>
               </a>
               <a href="https://www.facebook.com/nikoloz.chkoidze/" target="_blank" className={style.fifthpage_inner_link}>
                  <div className={style.fifthpage_inner_facebook_icon}></div>
                  <div className={style.fifthpage_inner_link_text}>Facebook</div>
               </a>
            </div>
         </div>
      </div>
   </div>
   <div className={style.footer}>ALL RIGHTS RESERVED © NIKOLOZ TCHKOIDZE PERSONAL WEBSITE</div>
</div>
</div>
  );
}

export default App;
